// ★內頁 不需要 @include style.sass(layout)已經有
// 說明 (完整說明見) src/sass/style.sass
//	* 此檔案包含：內頁共用元件
//		- HTML Tag
//		- 表單(Form)相關元件
//		- 修改框架/套件
//		- keyframes

// --------------------------------------------------------

/* Tag */

html,
body {
	overflow-x: hidden;
	overflow-y: auto;
	&.inside_bg{
		background-color: $gray-0;
		background-image: url('../images/inside_bg.png');
		background-repeat: repeat;
		header{
			background: $white;
		}
		.sec{
			z-index: 8;
		}
		.ft-float-circle{
			display: none;
		}
		.main-wrapper{
			padding-top: rem-calc(126);

			@include breakpoint(medium down) {
				padding-top: rem-calc(60);
			}
			@media screen and #{breakpoint(1024)} and #{breakpoint(1560 down)} {
				padding-top: rem-calc(106);
			}
		}
	}
}

img {
	pointer-events: none;
}

a,
button,
.button {
	&:focus {
		outline: none;
	}
}

a,
p {
	font-weight: $global-weight-normal;
	@include breakpoint(medium down) {
		font-size: rem-calc(14);
	}
}

// --------------------------------------------------------

/* 反選顏色 | Selection */
$selection-text-color: $white; // 反選的文字顏色
$selection-bg-color: darken($primary-color, 5%); // 反選的背景顏色

@mixin selection-color($text-color, $bg-color) {
	::selection {
		color: $text-color;
		background: $bg-color;

		// 當瀏覽器Tab非Focus狀態
		&:window-inactive {
			color: #323232;
			background: #c5c5c5;
		}
	}

	// Firefox版本: https://css-tricks.com/window-inactive-styling/#comment-92675
	// 但因為gulp-ruby-sass看不懂導致編譯錯誤(Invalid CSS)，所以無法使用
	// :-moz-window-inactive ::-moz-selection
	// 	color: #323232
	// 	background: #C5C5C5
}

// No Selection | 無法反選
// https://stackoverflow.com/a/4407335/11240898
@mixin user-select($val: none) {
	-webkit-touch-callout: $val; // iOS Safari
	-webkit-user-select: $val; // Safari
	-khtml-user-select: $val; // Konqueror HTML
	-moz-user-select: $val; // Firefox
	-ms-user-select: $val; // Internet Explorer/Edge
	user-select: $val; // Non-prefixed version, currently, supported by Chrome and Opera
}

// +selection-color(反選的文字顏色, 反選的背景顏色)
@include selection-color($selection-text-color, $selection-bg-color);

// 無法反選 | No Selection
.no-selection {
	@include user-select(none);
}

// --------------------------------------------------------

// Custom Scroll Bar
// Mixin 移動到　src\sass\layout\_variable_mixin_function.scss
// 需使用則開啟下列樣式
@include breakpoint-hover {
	@include custom-scroll-bar;

	.custom-scrollbar {
		@include custom-scroll-bar;
	}
}

// --------------------------------------------------------

/* Text */

.text-em {

	&,
	a {
		color: $primary-color;
	}
}

.text-thin {
	font-weight: 300;
}

.text-bold {
	font-weight: 500;
}

.text-boldest {
	font-weight: 700;
}

.text-underline {
	text-decoration: underline;
}

.text-note{
	font-size: rem-calc(12);
}
.text-14{
	font-size: rem-calc(14);
}

.text-brown{
	color: $brown;
}

.text-select{
	background: linear-gradient(to bottom, rgba($white, 0) 0%,rgba($white, 0) 50%,rgba($yellow, 0.2) 50%,rgba($yellow, 0.2) 100%);
}
// --------------------------------------------------------

/* a Link */

.black_link{
	@include set-link-color($black, $primary-color);
}
.black_link_td{
	@include set-link-color($black, $primary);
	text-decoration: underline;
}

.brown_link_td{
	@include set-link-color($brown, $primary);
	text-decoration: underline;
}

.white_link{
	@include set-link-color($white, $primary);
}
// --------------------------------------------------------

/* Image */

.img-absolute {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.img-rel {
	position: relative;
}

.img-deco {
	position: absolute;
	top: 0;
	left: 0;
}

/* object-fit */
// Fix the dimensions of an image and scale
// Default size: 1:1
//
// How to change size
// $imgWidth: // img width
// $imgHeight: // img height
// .object-fit:before{padding-top: round($imgHeight / $imgWidth * 100%)}
// round: round the number rounding
%object-fit-standard {
	position: relative;
	display: block; // Fixed <picture> bug
	overflow: hidden;

	&::before {
		content: '';
		display: block;
		padding-top: 100%;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100%;
		object-position: center center;
		background-position: center center;
	}
}

%object-fit-cover {
	img {
		object-fit: cover;
		background-size: cover;
	}
}

%object-fit-contain {
	background-position: center center;
	background-size: contain;

	img {
		object-fit: contain;
		background-size: contain;
	}
}

.object-fit {
	@extend %object-fit-standard;
	@extend %object-fit-cover;

	// 沒有內層(before)
	// &--wrap {
	// 	@extend %object-fit-standard;
	// 	@extend %object-fit-cover;

	// 	&::before {
	// 		display: none;
	// 	}
	// }

	// 應對不同比例的照片
	&--contain {
		@extend %object-fit-standard;
		@extend %object-fit-contain;
	}
}

// 大圖廣告使用：一班消息、講座資訊列表
.ad_bigsize_img{
	@include inside_boj_margin_s_60;
	border-radius: rem-calc(15);
	img{
		transition: all .3s;
	}
	&::before{
		@include before-img-size(1388, 405);
	}
	@include breakpoint-hover{
		&:hover, &:focus{
			.object-fit{
				box-shadow: $card-shadow;
			}
			img{
				transform: scale(1.1);
			}	
		}
	}
}
// --------------------------------------------------------

/* Main Content */
.main-wrapper {
	position: relative;
}

// --------------------------------------------------------

/* Help */

.m-0 {
	margin: 0;
}

.mt-0 {
	margin-top: 0;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.p-0 {
	padding: 0;
}

.pt-0 {
	padding-top: 0;
}

.pb-0 {
	padding-bottom: 0;
}

.no-bullet-m-p {
	list-style: none;
	margin: 0;
	padding: 0;
}

.rounded {
	&-full {
		border-radius: 999px;
	}
}

// 加速效能用
.translate3d {
	transform: translate3d(0, 0, 0);
}

// 定義 SVG 隱藏於頁面使用
.svg-def {
	position: absolute;
	top: -9999px;
	left: -9999px;
	overflow: hidden;
	width: 0;
	height: 0;
	opacity: 0;
	pointer-events: none;
}

.global-radius {
	--radius: 15px;

	border-radius: var(--radius);
	border-bottom-right-radius: 0;
}
