@mixin font-icon {
	@font-face {
		font-family: "icon";
		src: url('../fonts/icons/icon.eot');
		src: url('../fonts/icons/icon.eot?#iefix') format('embedded-opentype'),
		url('../fonts/icons/icon.woff2') format('woff2'),
		url('../fonts/icons/icon.woff') format('woff'),
		url('../fonts/icons/icon.ttf') format('truetype'),
		url('../fonts/icons/icon.svg#icon') format('svg');
		font-display: block;
		unicode-range: U+EA01-EA63;
	}

	.be-icon {
		font-family: sans-serif;
		line-height: 1;
	}

	.be-icon::before {
		@include font-icon-style;
	}
	
	/* Glyphs list */
	
	.be-icon-Calender::before {
		content: '\ea01';
	}
	
	.be-icon-air::before {
		content: '\ea02';
	}
	
	.be-icon-arrow-down::before {
		content: '\ea03';
	}
	
	.be-icon-arrow-left::before {
		content: '\ea04';
	}
	
	.be-icon-arrow-right::before {
		content: '\ea05';
	}
	
	.be-icon-arrow-up::before {
		content: '\ea06';
	}
	
	.be-icon-arrow_2_left::before {
		content: '\ea07';
	}
	
	.be-icon-arrow_2_right::before {
		content: '\ea08';
	}
	
	.be-icon-award::before {
		content: '\ea09';
	}
	
	.be-icon-blue_map::before {
		content: '\ea0a';
	}
	
	.be-icon-calculate::before {
		content: '\ea0b';
	}
	
	.be-icon-call::before {
		content: '\ea0c';
	}
	
	.be-icon-camera::before {
		content: '\ea0d';
	}
	
	.be-icon-camera_none::before {
		content: '\ea0e';
	}
	
	.be-icon-carpentry::before {
		content: '\ea0f';
	}
	
	.be-icon-carpentry_2::before {
		content: '\ea10';
	}
	
	.be-icon-chart::before {
		content: '\ea11';
	}
	
	.be-icon-check::before {
		content: '\ea12';
	}
	
	.be-icon-check_ok::before {
		content: '\ea13';
	}
	
	.be-icon-clearn::before {
		content: '\ea14';
	}
	
	.be-icon-click-top-2_circle::before {
		content: '\ea15';
	}
	
	.be-icon-click-top-2_hand::before {
		content: '\ea16';
	}
	
	.be-icon-click-top::before {
		content: '\ea17';
	}
	
	.be-icon-clock::before {
		content: '\ea18';
	}
	
	.be-icon-close::before {
		content: '\ea19';
	}
	
	.be-icon-color::before {
		content: '\ea1a';
	}
	
	.be-icon-construction::before {
		content: '\ea1b';
	}
	
	.be-icon-consult::before {
		content: '\ea1c';
	}
	
	.be-icon-design::before {
		content: '\ea1d';
	}
	
	.be-icon-destruction::before {
		content: '\ea1e';
	}
	
	.be-icon-display::before {
		content: '\ea1f';
	}
	
	.be-icon-door::before {
		content: '\ea20';
	}
	
	.be-icon-equipment::before {
		content: '\ea21';
	}
	
	.be-icon-eye-hide::before {
		content: '\ea22';
	}
	
	.be-icon-eye-show::before {
		content: '\ea23';
	}
	
	.be-icon-fb-msg::before {
		content: '\ea24';
	}
	
	.be-icon-fb::before {
		content: '\ea25';
	}
	
	.be-icon-floor::before {
		content: '\ea26';
	}
	
	.be-icon-floor_2::before {
		content: '\ea27';
	}
	
	.be-icon-gardening::before {
		content: '\ea28';
	}
	
	.be-icon-global::before {
		content: '\ea29';
	}
	
	.be-icon-good::before {
		content: '\ea2a';
	}
	
	.be-icon-heart-light::before {
		content: '\ea2b';
	}
	
	.be-icon-heart-solid::before {
		content: '\ea2c';
	}
	
	.be-icon-heart::before {
		content: '\ea2d';
	}
	
	.be-icon-home::before {
		content: '\ea2e';
	}
	
	.be-icon-hydropower::before {
		content: '\ea2f';
	}
	
	.be-icon-hydropower_2::before {
		content: '\ea30';
	}
	
	.be-icon-ig::before {
		content: '\ea31';
	}
	
	.be-icon-info::before {
		content: '\ea32';
	}
	
	.be-icon-infomation::before {
		content: '\ea33';
	}
	
	.be-icon-law::before {
		content: '\ea34';
	}
	
	.be-icon-license::before {
		content: '\ea35';
	}
	
	.be-icon-line::before {
		content: '\ea36';
	}
	
	.be-icon-link::before {
		content: '\ea37';
	}
	
	.be-icon-link_2::before {
		content: '\ea38';
	}
	
	.be-icon-location::before {
		content: '\ea39';
	}
	
	.be-icon-logout::before {
		content: '\ea3a';
	}
	
	.be-icon-m::before {
		content: '\ea3b';
	}
	
	.be-icon-mail::before {
		content: '\ea3c';
	}
	
	.be-icon-measure::before {
		content: '\ea3d';
	}
	
	.be-icon-member-data::before {
		content: '\ea3e';
	}
	
	.be-icon-minus::before {
		content: '\ea3f';
	}
	
	.be-icon-money::before {
		content: '\ea40';
	}
	
	.be-icon-mud_work::before {
		content: '\ea41';
	}
	
	.be-icon-mud_work_2::before {
		content: '\ea42';
	}
	
	.be-icon-no1::before {
		content: '\ea43';
	}
	
	.be-icon-notification::before {
		content: '\ea44';
	}
	
	.be-icon-oil_paint::before {
		content: '\ea45';
	}
	
	.be-icon-paint::before {
		content: '\ea46';
	}
	
	.be-icon-patner::before {
		content: '\ea47';
	}
	
	.be-icon-pen::before {
		content: '\ea48';
	}
	
	.be-icon-phone::before {
		content: '\ea49';
	}
	
	.be-icon-phone_2::before {
		content: '\ea4a';
	}
	
	.be-icon-pig::before {
		content: '\ea4b';
	}
	
	.be-icon-pin::before {
		content: '\ea4c';
	}
	
	.be-icon-plus::before {
		content: '\ea4d';
	}
	
	.be-icon-process::before {
		content: '\ea4e';
	}
	
	.be-icon-profile::before {
		content: '\ea4f';
	}
	
	.be-icon-protect::before {
		content: '\ea50';
	}
	
	.be-icon-py::before {
		content: '\ea51';
	}
	
	.be-icon-quotation::before {
		content: '\ea52';
	}
	
	.be-icon-reload::before {
		content: '\ea53';
	}
	
	.be-icon-room::before {
		content: '\ea54';
	}
	
	.be-icon-search::before {
		content: '\ea55';
	}
	
	.be-icon-shield_star::before {
		content: '\ea56';
	}
	
	.be-icon-standard::before {
		content: '\ea57';
	}
	
	.be-icon-star::before {
		content: '\ea58';
	}
	
	.be-icon-star_half::before {
		content: '\ea59';
	}
	
	.be-icon-star_null::before {
		content: '\ea5a';
	}
	
	.be-icon-storage::before {
		content: '\ea5b';
	}
	
	.be-icon-style::before {
		content: '\ea5c';
	}
	
	.be-icon-system::before {
		content: '\ea5d';
	}
	
	.be-icon-system_2::before {
		content: '\ea5e';
	}
	
	.be-icon-talk::before {
		content: '\ea5f';
	}
	
	.be-icon-ticket::before {
		content: '\ea60';
	}
	
	.be-icon-tool::before {
		content: '\ea61';
	}
	
	.be-icon-wallpaper::before {
		content: '\ea62';
	}
	
	.be-icon-yt::before {
		content: '\ea63';
	}
	
}

// <i>:before Style
@mixin font-icon-style {
	font-family: "icon";
	
	// Font smoothing. That was taken from TWBS
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-style: normal;
	font-weight: normal;

	// For safety - reset parent styles, that can break glyph codes
	font-variant: normal;
	text-transform: none;
	text-decoration: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	// only necessary if not using the private unicode range (firstGlyph option)
	speak: none;

	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: .2em;
	text-align: center;

	// fix buttons height, for twitter bootstrap
	line-height: 1em;

	// Animation center compensation - margins should be symmetric
	// remove if not needed
	margin-left: .2em;

	// you can be more comfortable with increased icons size
	// font-size: 120%;

	// Uncomment for 3D effect
	// text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
}

// @include font-icon;

// SASS變數使用範例
// .icon {
//     &:before{
// 		@include font-icon-add(SVG檔案名稱);
//     }
// }

// 內頁使用之Style
%font-icon-style {
	@include font-icon-style;
}


$font-icon-Calender: '\ea01';

$font-icon-air: '\ea02';

$font-icon-arrow-down: '\ea03';

$font-icon-arrow-left: '\ea04';

$font-icon-arrow-right: '\ea05';

$font-icon-arrow-up: '\ea06';

$font-icon-arrow_2_left: '\ea07';

$font-icon-arrow_2_right: '\ea08';

$font-icon-award: '\ea09';

$font-icon-blue_map: '\ea0a';

$font-icon-calculate: '\ea0b';

$font-icon-call: '\ea0c';

$font-icon-camera: '\ea0d';

$font-icon-camera_none: '\ea0e';

$font-icon-carpentry: '\ea0f';

$font-icon-carpentry_2: '\ea10';

$font-icon-chart: '\ea11';

$font-icon-check: '\ea12';

$font-icon-check_ok: '\ea13';

$font-icon-clearn: '\ea14';

$font-icon-click-top-2_circle: '\ea15';

$font-icon-click-top-2_hand: '\ea16';

$font-icon-click-top: '\ea17';

$font-icon-clock: '\ea18';

$font-icon-close: '\ea19';

$font-icon-color: '\ea1a';

$font-icon-construction: '\ea1b';

$font-icon-consult: '\ea1c';

$font-icon-design: '\ea1d';

$font-icon-destruction: '\ea1e';

$font-icon-display: '\ea1f';

$font-icon-door: '\ea20';

$font-icon-equipment: '\ea21';

$font-icon-eye-hide: '\ea22';

$font-icon-eye-show: '\ea23';

$font-icon-fb-msg: '\ea24';

$font-icon-fb: '\ea25';

$font-icon-floor: '\ea26';

$font-icon-floor_2: '\ea27';

$font-icon-gardening: '\ea28';

$font-icon-global: '\ea29';

$font-icon-good: '\ea2a';

$font-icon-heart-light: '\ea2b';

$font-icon-heart-solid: '\ea2c';

$font-icon-heart: '\ea2d';

$font-icon-home: '\ea2e';

$font-icon-hydropower: '\ea2f';

$font-icon-hydropower_2: '\ea30';

$font-icon-ig: '\ea31';

$font-icon-info: '\ea32';

$font-icon-infomation: '\ea33';

$font-icon-law: '\ea34';

$font-icon-license: '\ea35';

$font-icon-line: '\ea36';

$font-icon-link: '\ea37';

$font-icon-link_2: '\ea38';

$font-icon-location: '\ea39';

$font-icon-logout: '\ea3a';

$font-icon-m: '\ea3b';

$font-icon-mail: '\ea3c';

$font-icon-measure: '\ea3d';

$font-icon-member-data: '\ea3e';

$font-icon-minus: '\ea3f';

$font-icon-money: '\ea40';

$font-icon-mud_work: '\ea41';

$font-icon-mud_work_2: '\ea42';

$font-icon-no1: '\ea43';

$font-icon-notification: '\ea44';

$font-icon-oil_paint: '\ea45';

$font-icon-paint: '\ea46';

$font-icon-patner: '\ea47';

$font-icon-pen: '\ea48';

$font-icon-phone: '\ea49';

$font-icon-phone_2: '\ea4a';

$font-icon-pig: '\ea4b';

$font-icon-pin: '\ea4c';

$font-icon-plus: '\ea4d';

$font-icon-process: '\ea4e';

$font-icon-profile: '\ea4f';

$font-icon-protect: '\ea50';

$font-icon-py: '\ea51';

$font-icon-quotation: '\ea52';

$font-icon-reload: '\ea53';

$font-icon-room: '\ea54';

$font-icon-search: '\ea55';

$font-icon-shield_star: '\ea56';

$font-icon-standard: '\ea57';

$font-icon-star: '\ea58';

$font-icon-star_half: '\ea59';

$font-icon-star_null: '\ea5a';

$font-icon-storage: '\ea5b';

$font-icon-style: '\ea5c';

$font-icon-system: '\ea5d';

$font-icon-system_2: '\ea5e';

$font-icon-talk: '\ea5f';

$font-icon-ticket: '\ea60';

$font-icon-tool: '\ea61';

$font-icon-wallpaper: '\ea62';

$font-icon-yt: '\ea63';


// 可以帶入變數
@mixin font-icon-add($icon: '', $extendStyle: true) {
	
	@if $icon == Calender {
		content: '\ea01';
	}
	
	@if $icon == air {
		content: '\ea02';
	}
	
	@if $icon == arrow-down {
		content: '\ea03';
	}
	
	@if $icon == arrow-left {
		content: '\ea04';
	}
	
	@if $icon == arrow-right {
		content: '\ea05';
	}
	
	@if $icon == arrow-up {
		content: '\ea06';
	}
	
	@if $icon == arrow_2_left {
		content: '\ea07';
	}
	
	@if $icon == arrow_2_right {
		content: '\ea08';
	}
	
	@if $icon == award {
		content: '\ea09';
	}
	
	@if $icon == blue_map {
		content: '\ea0a';
	}
	
	@if $icon == calculate {
		content: '\ea0b';
	}
	
	@if $icon == call {
		content: '\ea0c';
	}
	
	@if $icon == camera {
		content: '\ea0d';
	}
	
	@if $icon == camera_none {
		content: '\ea0e';
	}
	
	@if $icon == carpentry {
		content: '\ea0f';
	}
	
	@if $icon == carpentry_2 {
		content: '\ea10';
	}
	
	@if $icon == chart {
		content: '\ea11';
	}
	
	@if $icon == check {
		content: '\ea12';
	}
	
	@if $icon == check_ok {
		content: '\ea13';
	}
	
	@if $icon == clearn {
		content: '\ea14';
	}
	
	@if $icon == click-top-2_circle {
		content: '\ea15';
	}
	
	@if $icon == click-top-2_hand {
		content: '\ea16';
	}
	
	@if $icon == click-top {
		content: '\ea17';
	}
	
	@if $icon == clock {
		content: '\ea18';
	}
	
	@if $icon == close {
		content: '\ea19';
	}
	
	@if $icon == color {
		content: '\ea1a';
	}
	
	@if $icon == construction {
		content: '\ea1b';
	}
	
	@if $icon == consult {
		content: '\ea1c';
	}
	
	@if $icon == design {
		content: '\ea1d';
	}
	
	@if $icon == destruction {
		content: '\ea1e';
	}
	
	@if $icon == display {
		content: '\ea1f';
	}
	
	@if $icon == door {
		content: '\ea20';
	}
	
	@if $icon == equipment {
		content: '\ea21';
	}
	
	@if $icon == eye-hide {
		content: '\ea22';
	}
	
	@if $icon == eye-show {
		content: '\ea23';
	}
	
	@if $icon == fb-msg {
		content: '\ea24';
	}
	
	@if $icon == fb {
		content: '\ea25';
	}
	
	@if $icon == floor {
		content: '\ea26';
	}
	
	@if $icon == floor_2 {
		content: '\ea27';
	}
	
	@if $icon == gardening {
		content: '\ea28';
	}
	
	@if $icon == global {
		content: '\ea29';
	}
	
	@if $icon == good {
		content: '\ea2a';
	}
	
	@if $icon == heart-light {
		content: '\ea2b';
	}
	
	@if $icon == heart-solid {
		content: '\ea2c';
	}
	
	@if $icon == heart {
		content: '\ea2d';
	}
	
	@if $icon == home {
		content: '\ea2e';
	}
	
	@if $icon == hydropower {
		content: '\ea2f';
	}
	
	@if $icon == hydropower_2 {
		content: '\ea30';
	}
	
	@if $icon == ig {
		content: '\ea31';
	}
	
	@if $icon == info {
		content: '\ea32';
	}
	
	@if $icon == infomation {
		content: '\ea33';
	}
	
	@if $icon == law {
		content: '\ea34';
	}
	
	@if $icon == license {
		content: '\ea35';
	}
	
	@if $icon == line {
		content: '\ea36';
	}
	
	@if $icon == link {
		content: '\ea37';
	}
	
	@if $icon == link_2 {
		content: '\ea38';
	}
	
	@if $icon == location {
		content: '\ea39';
	}
	
	@if $icon == logout {
		content: '\ea3a';
	}
	
	@if $icon == m {
		content: '\ea3b';
	}
	
	@if $icon == mail {
		content: '\ea3c';
	}
	
	@if $icon == measure {
		content: '\ea3d';
	}
	
	@if $icon == member-data {
		content: '\ea3e';
	}
	
	@if $icon == minus {
		content: '\ea3f';
	}
	
	@if $icon == money {
		content: '\ea40';
	}
	
	@if $icon == mud_work {
		content: '\ea41';
	}
	
	@if $icon == mud_work_2 {
		content: '\ea42';
	}
	
	@if $icon == no1 {
		content: '\ea43';
	}
	
	@if $icon == notification {
		content: '\ea44';
	}
	
	@if $icon == oil_paint {
		content: '\ea45';
	}
	
	@if $icon == paint {
		content: '\ea46';
	}
	
	@if $icon == patner {
		content: '\ea47';
	}
	
	@if $icon == pen {
		content: '\ea48';
	}
	
	@if $icon == phone {
		content: '\ea49';
	}
	
	@if $icon == phone_2 {
		content: '\ea4a';
	}
	
	@if $icon == pig {
		content: '\ea4b';
	}
	
	@if $icon == pin {
		content: '\ea4c';
	}
	
	@if $icon == plus {
		content: '\ea4d';
	}
	
	@if $icon == process {
		content: '\ea4e';
	}
	
	@if $icon == profile {
		content: '\ea4f';
	}
	
	@if $icon == protect {
		content: '\ea50';
	}
	
	@if $icon == py {
		content: '\ea51';
	}
	
	@if $icon == quotation {
		content: '\ea52';
	}
	
	@if $icon == reload {
		content: '\ea53';
	}
	
	@if $icon == room {
		content: '\ea54';
	}
	
	@if $icon == search {
		content: '\ea55';
	}
	
	@if $icon == shield_star {
		content: '\ea56';
	}
	
	@if $icon == standard {
		content: '\ea57';
	}
	
	@if $icon == star {
		content: '\ea58';
	}
	
	@if $icon == star_half {
		content: '\ea59';
	}
	
	@if $icon == star_null {
		content: '\ea5a';
	}
	
	@if $icon == storage {
		content: '\ea5b';
	}
	
	@if $icon == style {
		content: '\ea5c';
	}
	
	@if $icon == system {
		content: '\ea5d';
	}
	
	@if $icon == system_2 {
		content: '\ea5e';
	}
	
	@if $icon == talk {
		content: '\ea5f';
	}
	
	@if $icon == ticket {
		content: '\ea60';
	}
	
	@if $icon == tool {
		content: '\ea61';
	}
	
	@if $icon == wallpaper {
		content: '\ea62';
	}
	
	@if $icon == yt {
		content: '\ea63';
	}
	

	@if $extendStyle == true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}