@import './layout/common';
@import './components/_form_promise';
// 通用諮詢彈窗：作品細節頁面、預售屋夢想詢問彈窗、
@mixin consult_popup {
	height: 85vh;
	overflow: hidden;
	padding-bottom: rem-calc(16);
	padding-right: 0;

	form{
		gap: #{rem-calc(20)};
		height: 100%;
		@include tiny-scrollbar($gray-50, $white);
		overflow-y: auto;
		padding-right: rem-calc(15);
	}

	@include promise;

	@include breakpoint(small only) {
		
		height: 80vh;
		overflow: auto;
		
		.verification_code{
			margin-bottom: rem-calc(20);
		}

		&.has-bg::after{
			display: none;
		}
	}
}
