// Dropdown Menu
.dropdown.menu {

	&,
	ul {
		list-style: none;
	}

	>li {
		>a {
			padding-left: rem-calc(4);
			padding-top: rem-calc(2);
			padding-bottom: rem-calc(3);
			padding-right: rem-calc(3);
			font-size: rem-calc(12);
			border: 1px solid $black;
			border-radius: 99px;
			color: $black;

			i {
				display: inline-block;
				margin-right: rem-calc(4);
				font-size: rem-calc(20);
				transform: translate(0, 1px);
			}
		}

		&.is-dropdown-submenu-parent>a {
			&::after {
				@include font-icon-add(arrow-down);

				right: 2px;
				width: auto;
				height: auto;
				margin-top: -0.5em;
				border: 0 none;
				font-size: rem-calc(15);
				transform: translate(0, 1px);
			}
		}
	}

	.menu {
		--radius: #{rem-calc(15)};

		top: -1px !important; // 為了蓋過框架樣式
		margin: 0;
		min-width: 145px;
		font-size: rem-calc(12);
		border-radius: var(--radius);
		border: 0 none;
		box-shadow: $primary-shadow;

		// 向右開(預設)
		&.opens-right {
			left: -1px !important; // 為了蓋過框架樣式
		}

		// 向左開
		&.opens-left {
			right: -1px !important; // 為了蓋過框架樣式
		}

		a {
			position: relative;
			display: flex;
			padding: rem-calc(6 10);
			color: #333;
			align-items: center;

			&:not(.is-active) {

				&:hover,
				&:focus {
					color: $primary-light-1;

					&::after {
						@include font-icon-add(arrow-right);
					}
				}
			}
		}

		i {
			font-size: rem-calc(20);
		}

		.is-active {
			background: $primary-light-1;
			color: $white;
			pointer-events: none;
		}
	}

	.selector-clone {
		border-top-left-radius: var(--radius);
		border-top-right-radius: var(--radius);
		background: $primary-light-1;
		pointer-events: none;

		a {
			color: $white;
		}
	}
}
