.btn-box {
	margin-top: rem-calc(40);

	@include breakpoint(medium) {
		margin-top: rem-calc(60);
	}
}

.button {
	@include breakpoint(small only) {
		padding: em-calc(8 / 14) em-calc(14 / 14);
		font-size: rem-calc(14);
	}
	&.brown_full{
		background-color: $brown;
		&:hover,
		&:focus {
			background: $primary-light-1;
			color: $white;
		}
	}
	&.orange{
		background-color: $orange;
		&:hover,
		&:focus {
			background: $primary-light-2;
			color: $white;
		}
	}

	&.tiny {
		--pd-x: #{(18 / 12 * 1em)};
		--pd-y: #{(9 / 12 * 1em)};

		padding: var(--pd-y) var(--pd-x);

		font-size: rem-calc(12);
		
	}

	&.dark {
		color: $dark-color;

		&:hover,
		&:focus {
			color: $primary-light-1;
		}
	}

	&.hollow {
		&.dark {
			border-color: $dark-color;

			&:hover,
			&:focus {
				background: $primary-light-1;
				color: $white;
				border-color: $white;
			}
		}
		&.brown{
			border-color: $brown;
			color: $brown;
			&:hover,
			&:focus {
				background: $primary-light-1;
				color: $white;
				border-color: $white;
			}
		}
		&.yellow{
			border-color: $yellow;
			color: $yellow;
			&:hover,
			&:focus {
				background: $primary-light-1;
				color: $white;
				border-color: $white;
			}
		}
		&.gray_300{
			border-color: $gray-300;
			color: $gray-300;
			&:hover,
			&:focus {
				background: $primary-light-1;
				color: $white;
				border-color: $white;
			}
		}
	}

	&.shadow {
		box-shadow: $primary-shadow;
	}

	&.line_at {
		background: #06C755;
		transition: background-color .25s ease-out, color .25s ease-out;
		@include breakpoint-hover {
			&:hover,
			&:focus{
				background: $primary;
				color: $white;
				border-color: $white;
			}
		}
	}
}

// 線框按鈕
.button-border {
	background-color: transparent;
	color: #000;
	border: 1px solid currentColor;
	border-radius: 99px;
}

// 加入喜歡按鈕
%like-active{
	color: $white;

	.be-icon-heart {
		position: relative;
		opacity: 0;
		transition: opacity 0.3s 1s;
	}

	.solid {
		opacity: 1;
		animation: heart-beat 1s;
	}
}
.btn-like {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	line-height: 1;
	font-size: rem-calc(40);
	color: $white;
	cursor: pointer;

	&.dark {
		color: $red;
		&.is-active {
			.solid{
				color: $white;
			}
		}
	}
	&.gray {
		color: $gray-50;
		&.is-active {
			.solid{
				color: $white;
			}
		}
	}

	i {
		&::before {
			margin: 0;
		}
	}

	.solid {
		opacity: 0;
		pointer-events: none;
	}

	.be-icon-heart-solid {
		color: $red;
	}

	.be-icon-heart-light {
		transform: translate(-20%, 6%);
	}

	&.is-active {
		@extend %like-active;
	}

	@include breakpoint(medium) {
		font-size: rem-calc(48);
	}

	@include breakpoint-hover {
		transition: color 0.3s;

		&:active {
			transform: scale(0.8);
		}

		&:hover,
		&:focus {
			color: $pink;
		}
	}
}

.btn-like_normal{
	--pd:  #{rem-calc(50)};
	--btn-icon-size:  #{rem-calc(25)};
	position: relative;
	display: flex;
    align-items: center;
	background-color: $orange;
	height: rem-calc(45);
	color: $white;
	border-radius: rem-calc(50);
	padding-top: rem-calc(12);
	padding-bottom: rem-calc(12);
	padding-right: var(--pd);
	padding-left: var(--pd);
	cursor: pointer;
	.btn-like{
		width: var(--btn-icon-size);		
		height: var(--btn-icon-size);
		font-size: var(--btn-icon-size);
		position: relative;
		line-height: 1;
		i{
			font-size: var(--btn-icon-size);
		}
	}
	.text_in{
		font-size: rem-calc(16);
	}
	&.is-active {
		.btn-like{
			@extend %like-active;
		}
		background-color: $primary-light-2;
	}
	@include breakpoint-hover {
		transition: all 0.3s;

		&:hover,
		&:focus,
		&:active {
			background-color: $primary-light-2;
		}
	}
	@include breakpoint(medium only) {
		--pd:  #{rem-calc(25)};
		--btn-icon-size:  #{rem-calc(20)};
	}
	@include breakpoint(small only) {
		--pd:  #{rem-calc(20)};
		--btn-icon-size:  #{rem-calc(20)};
		height: rem-calc(35);
		.text_in{
			font-size: rem-calc(14);
		}
	}
}
.btn-like_circle{
	--btn-icon-size:  #{rem-calc(35)};
	--btn-size:  #{rem-calc(50)};
	position: relative;
	display: block;
	background-color: $white;
	width: var(--btn-size);
	height: var(--btn-size);
	border-radius: 50%;
	overflow: hidden;
	cursor: pointer;
	.btn-like{
		color: $gray-600;
		width: var(--btn-icon-size);		
		height: var(--btn-icon-size);
		font-size: var(--btn-icon-size);
		line-height: 1;
		top: calc((var(--btn-size) - var(--btn-icon-size)) / 2 - 2px);
		left: calc((var(--btn-size) - var(--btn-icon-size)) / 2);
		i{
			font-size: var(--btn-icon-size);
		}
	}
	&.is-active {
		.btn-like{
			@extend %like-active;
		}
		background-color: $primary-light-2;
	}
	@include breakpoint-hover {
		transition: all 0.3s;

		&:hover,
		&:focus,
		&:active {
			background-color: $primary-light-2;
		}
	}
	@include breakpoint(medium only) {
		--pd:  #{rem-calc(25)};
		--btn-icon-size:  #{rem-calc(20)};
	}
	@include breakpoint(small only) {
		--pd:  #{rem-calc(20)};
		--btn-icon-size:  #{rem-calc(20)};
		height: rem-calc(35);
	}
}

@keyframes heart-beat {
	0% {
		opacity: 1;
		transform: scale(1);
	}

	30% {
		opacity: 1;
		transform: scale(0.3);
	}

	60% {
		transform: scale(1.5);
	}

	100% {
		transform: scale(1);
	}
}
