/* Cookie box */
.notification-box {
	$root: &;

	position: fixed;
	z-index: 1000;
	bottom: 0;
	left: 0;
	width: 100%;
	font-size: rem-calc(12);
	background-color: $white;
	box-shadow: 0 0 10px rgba($black, 0.2);
	animation: cookieToShow 1s;

	&__cont {
		--pd-y: #{rem-calc(16)};

		position: relative;
		display: flex;
		align-items: center;
		padding-top: var(--pd-y);
		padding-bottom: var(--pd-y);
		color: $black;

	}

	&__text-box {
		margin-right: rem-calc(15);

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}

		a {
			color: $black;
			text-decoration: underline;
		}
	}

	&.is-hide {
		display: none;
	}

	&.to-hide {
		animation: cookieToHide 0.5s forwards;
	}

	.button {
		margin-bottom: 0;
		min-width: rem-calc(74);
	}

	@include breakpoint(large) {
		&__cont {
			justify-content: center;
		}

		&__text-box {
			margin-right: rem-calc(20);

			p {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	@include breakpoint-hover {
		&__text-box {
			a {

				&:hover,
				&:focus {
					color: $primary-color;
				}
			}
		}

		.button {

			&:hover,
			&:focus {
				background: $primary-color;
				color: $white;
				border-color: $primary-color;
			}
		}
	}

	@keyframes cookieToShow {
		0% {
			transform: translateY(100%);
		}
	}

	@keyframes cookieToHide {
		100% {
			transform: translateY(100%);
		}
	}
}

// END Cookie box
