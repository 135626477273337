@import './layout/common';
// icon 的圓圈
.icon-box {
	--size: #{rem-calc(60)};

	display: flex;
	align-items: center;
	justify-content: center;
	width: var(--size);
	height: var(--size);
	font-size: var(--font-size, calc(var(--size) * 0.7));
	color: var(--icon-color, $white);
	background: var(--icon-bg, $primary-color);
	border: 1px solid var(--icon-border-color, transparent);
	border-radius: 999px;

	i {
		pointer-events: none;

		&::before {
			margin: 0;
		}
	}
}
// 獎項漸層
@mixin iconLinear($size, $fontSize){
	background-clip: padding-box;
	border: 1px solid transparent;
	background: rgba($white, 0.8);
	box-sizing: border-box;
	width: $size;
	height: $size;
	font-size: $fontSize;
	&:before,&:after{
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
		
	&::before{
		z-index: -2;
		margin: -2px;
		border-radius: inherit;
		background: linear-gradient(to right, $orange, #FAE7D6);
	}
		
	&::after{
		z-index: -1;
		background: $white;
		border-radius: inherit;
	}
		
	i{
		background: -webkit-gradient(linear, left top, left bottom, from($orange), to(#FAE7D6));
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}
