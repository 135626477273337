// Flex
.flex-between-middle {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flex-center-middle {
	display: flex;
	justify-content: center;
	align-items: center;
}
