// Form / Input / Select / Textarea

%input-global {
	border-color: $gray-300;
	box-shadow: none;

	&:focus {
		box-shadow: none; }

	@include breakpoint-hover {
		&:hover,
		&:focus {
			border-color: $black; } }

	&.is-invalid-input {
		&:not(:focus) {
			background: $white; } } }

%input-height {
	height: rem-calc(45); }

#{text-inputs()} {
	@extend %input-global;
	@extend %input-height; }

input {
	&[type='date'] {
		// 隱藏預設右側月曆Icon
		&::-webkit-inner-spin-button,
		&::-webkit-calendar-picker-indicator {
			display: none;
			-webkit-appearance: none; }

		@include breakpoint-hover {
			cursor: pointer; } }

	// UAT245: 修正 iOS 15 後日期欄位會置中問題
	// Ref: https://simplernerd.com/js-align-text-left-ios-date-input/
	&[type='date'],
	&::-webkit-date-and-time-value {
		text-align: left;
		text-align: -webkit-left; }
	// 移除number的上下箭頭
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0; }
	&[type="number"] {
		-moz-appearance: textfield; } }


select {
	@extend %input-global;
	@extend %input-height;
	// 寫於 _variable_mixin_function
	@include select-style;

	option:disabled {
		cursor: not-allowed; } }


textarea {
	@extend %input-global;

	@include custom-scroll-bar(#d4d3d1, $white);

	min-width: 100%;
	min-height: rem-calc(100);
	resize: vertical;
	border-radius: rem-calc(15);

	@include breakpoint(large) {
		min-height: rem-calc(150); } }
label {
	cursor: pointer;
	font-size: inherit; }

.input-normal {
	input, select, textarea {
		margin: rem-calc(10 0 20 0); }
	// select option 設定
	select {
		@include select-style;
		border-radius: rem-calc(99); }

	.form-error {
		margin-top: rem-calc(-15); }
	.is-invalid-select {
		border-color: $red;
		color: $red; }
	.verification_code {
		display: flex;
		align-items: center;
		gap: #{rem-calc(10)};

		img {
			max-height: #{rem-calc(42)};
			margin-top: rem-calc(15); }

		i {
			font-size: #{rem-calc(35)} !important; }

		.black_link {
			margin-top: rem-calc(15);
			cursor: pointer;
			.be-icon::before {
				transform: scale(1); } } } }

// 設定欄位有按鈕---------------------
.input_btn_group {
	display: inline-flex;
	justify-content: space-between;
	label, .pw_all {
		width: calc(100% - 120px - 20px); }
	.btn_100 {
		width: rem-calc(120);
		margin-top: rem-calc(38);
		height: rem-calc(45);
		display: flex;
		align-items: center;
		justify-content: center;
		padding: rem-calc(0); }
	@include breakpoint(large only) {
		label, .pw_all {
			width: calc(100% - 100px - 10px); }
		.btn_100 {
			width: rem-calc(100); } }
	@include breakpoint(medium only) {
		label, .pw_all {
			width: calc(100% - 100px - 10px); }
		.btn_100 {
			width: rem-calc(100); } }
	@include breakpoint(small only) {
		label, .pw_all {
			width: calc(100% - 105px - 20px); }
		.btn_100 {
			width: rem-calc(105); } }
	&.all_btn {
		.button {
			height: rem-calc(45);
			margin-top: rem-calc(38); }
		button:first-child {
			width: calc(100% - 120px - 20px);
			@include breakpoint(large only) {
				width: calc(100% - 100px - 10px); }
			@include breakpoint(medium only) {
				width: calc(100% - 100px - 10px); }
			@include breakpoint(small only) {
				width: calc(100% - 105px - 20px); } } }
	//設定重設密-會員專區
	&.many_input {
		label {
			width: 100%; }
		.pw_all {
			margin-right: rem-calc(20);
			width: calc((100% - 120px - 20px) / 2); }
		@include breakpoint(large only) {
			.pw_all {
				margin-right: rem-calc(10);
				width: calc((100% - 100px - 10px) / 2); } }
		@include breakpoint(medium down) {
			display: flex;
			flex-direction: column;
			align-items: flex-end;
			.pw_all {
				width: 100%;
				margin-right: 0;
				margin-bottom: rem-calc(0);
				display: flex;
				flex-direction: column; }
			.btn_100 {
				width: 100%;
				margin-top: 0; } } } }

// input has icon設定---------------------
.icon-wrapper {
	position: relative;
	display: block;
	height: rem-calc(45);
	margin: rem-calc(10 0 20 0);
	input {
		margin: 0; }
	// .form-error
	// 	margin-top: rem-calc(5)
	.unmask {
		position: absolute;
		right: rem-calc(22);
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		width: rem-calc(25);
		height: rem-calc(25);
		border-radius: 50%;
		cursor: pointer;
		border: none;
		-webkit-appearance: none;
		color: black;
		&.error {
			color: $red; }
		i {
			font-size: rem-calc(25); } }
	&.edit_style {
		display: inherit;
		input {
			width: auto;
			border: none;
			border-radius: 0;
			padding: 0;
			background: transparent; } }
	.nextBtn {
		position: relative; } }

//地址設定---------------------
.select_2 {
	select {
		width: calc(50% - 15px);
		background-color: $white;
		&:nth-child(1) {
			margin-right: rem-calc(15); }
		&:nth-child(2) {
			margin-left: rem-calc(15); }

		@include breakpoint(small only) {
			width: 100%;
			&:nth-child(1) {
				margin-right: rem-calc(0); }
			&:nth-child(2) {
				margin-left: rem-calc(0); } } } }

// checkbox radio 設定---------------------
$color-primary: $primary;
$color-disabled-light: #F6F8FF;
$color-disabled-dark: #8F8F8F;

$form-element-input__background-color: $white;
$form-element-input__border-color: $gray-350;
$form-element-input__checked__background-color: $white;
$form-element-input__checked__border-color: $color-primary;
$form-element-input__hover__background-color: $color-primary;

$form-element-input__disabled__background-color: $color-disabled-light;
$form-element-input__disabled__border-color: $color-disabled-dark;
$form-element-input__disabled-checked__background-color: $color-disabled-dark;
$form-element-input-check__disabled__color: $color-disabled-dark;
$form-element-input-check__disabled-checked__color: $color-disabled-light;
$form-element-input--border-color: $gray-350;


input[type='checkbox'],
input[type='radio'] {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	height: rem-calc(30);
	display: inline-block;
	vertical-align: top;
	position: relative;
	margin: 0;
	cursor: pointer;
	border: 1px solid $form-element-input__border-color;
	background: $form-element-input__background-color;
	transition: background .3s, border-color .3s, outline .2s;
	@include breakpoint(medium down) {
		height: rem-calc(20);
		width: rem-calc(20); }
	&:after {
		content: '';
		display: block;
		left: 0;
		top: 0;
		position: absolute;
		transition: transform .3s ease, opacity .2s; }

	&:checked {
		background-color: $form-element-input__checked__background-color;
		border-color: $form-element-input__checked__border-color;
		transition: transform .6s cubic-bezier(.2, .85, .32, 1.2), opacity .3s; }

	&:disabled {
		background-color: $form-element-input__disabled__background-color;
		border-color: $form-element-input__disabled__border-color;
		cursor: not-allowed;

		&:checked {
			background-color: $form-element-input__disabled-checked__background-color; }

		& + label {
			cursor: not-allowed; } }

	&:hover {
		&:not(:checked) {
			&:not(:disabled) {
				border-color: $form-element-input__hover__background-color; } } }


	&:focus-visible {
		outline: 2px solid $color-primary;
		outline-offset: 4px; }

	&:not(.switch) {
		width: rem-calc(30);
		@include breakpoint(medium down) {
			width: rem-calc(20);
			height: rem-calc(20); } }
	& + label {
		display: inline-block;
		cursor: pointer;
		font-size: rem-calc(16);
		margin-left: .2em;
		vertical-align: top;
		@include breakpoint(medium down) {
			font-size: rem-calc(14);
			line-height: 20px; } } }

input[type='radio'] {
	&:checked {
		background-color: $primary; } }
input[type='checkbox'] {
	&:not(.switch) {
		border-radius: rem-calc(8);

		&:after {
			border: 2px solid $form-element-input__background-color;
			height: 45%;
			width: 25%;
			border-top: 0;
			border-left: 0;
			left: 38%;
			top: 20%;
			transform: rotate(20deg); }

		&:disabled:not(:checked):after {
			border-color: $form-element-input__disabled__background-color; }

		&:checked:after {
			transform: rotate(43deg);
			border-color: $color-primary; }
		@include breakpoint(medium down) {
			border-radius: rem-calc(5); } }
	&.switch {
		width: rem-calc(80);
		border-radius: rem-calc(50);
		height: rem-calc(35);
		background: $gray-50;
		border-color: $gray-50;

		&:after {
			left: 5%;
			top: 2.5px;
			border-radius: 50%;
			width: rem-calc(28); // 50% - <left>
			height: rem-calc(28); // 100% - (<top> * 2)
			background: $white;
			transform: translateX(0); }

		&:checked {
			background: $primary;
			border-color: $primary;
			&:after {
				background: $white;
				transform: translateX(150%); } }

		&:disabled {
			&:not(:checked) {
				&:after {
					background-color: $form-element-input-check__disabled__color; } } } } }
.round-checkbox {
	--size: #{rem-calc(30)};
	width: var(--size);
	height: var(--size);
	input[type='checkbox'] {
		border-radius: 50%;
		&:checked {
			background-color: $primary; }
		&::after {
			border-color: $white; }
		&:not(.switch):checked {
			width: var(--size);
			height: var(--size);
			&:after {
				border-color: $white; } } } }


input[type='radio'] {
	border-radius: 50%;

	&:after {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background: $form-element-input__background-color;
		transform: scale(.7); }


	&:disabled:after {
		background: $form-element-input__disabled__background-color; }


	&:checked:after {
		transform: scale(.5); } }


//時間欄位設定---------------------
.datepicker--day-name {
	font-weight: 500; }
.datepicker--cell.-selected- {
	background: $primary !important; }

.datepicker--cell.-current- {
	color: $primary !important; }


// slider 拉霸設定 區間選擇---------------------
// https://get.foundation/sites/docs/slider.html
.slider-handle {
	border: 2px solid $primary;
	&:hover {
		background: $primary; } }
.slider {
	border-radius: rem-calc(20);
	margin-bottom: rem-calc(20); }
.slider_controls_list {
	input {
		width: rem-calc(35);
		text-align: center;
		border: none; } }
