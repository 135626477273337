@import './layout/common';
// 承諾樣式
@mixin promise {
    .promise {
        margin-bottom: #{rem-calc(20)};
        .grid-x{
            background: $gray-0;
            padding: rem-calc(20);
        }
        p {
            color: #fc847b;
            padding-left: #{rem-calc(20)};
            margin-bottom: 0;
        }
        img {
            width: 100%;
            object-fit: contain;
            margin-left: rem-calc(-40);
        }
    }
    @include breakpoint(small only) {
        
        .promise {
            .grid-x{
                padding: rem-calc(10 0);
            }
            img {
                margin-left: rem-calc(-20);
            }
        }
        .deal{
            display: flex;
            gap:  rem-calc(10);
            input{
                width: rem-calc(23);
            }
            label{
                width: calc(100% - #{rem-calc(23)} - 10px);
            }
        }
    }
}
