/* Loader */
.loader {
	$root: &;

	position: fixed;
	z-index: 10000;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	color: $gray-300;
	background: rgba($white, 0.5);
	transition: opacity 0.5s;

	&__cont {
		--size: #{rem-calc(100)};
		--mg: calc(var(--size) / -2);

		position: absolute;
		z-index: 1;
		left: 50%;
		top: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		margin-top: var(--mg);
		margin-left: var(--mg);
		width: var(--size);
		height: var(--size);
		background-color: $white;
		border-radius: 100%;
		transition: transform 1s, opacity 1s;

		&::before {
			--circle-size: calc(var(--size) * 1.2);
			--mg: calc(var(--circle-size) / -2);
			--border-color: currentColor;

			content: '';
			position: absolute;
			left: 50%;
			top: 50%;
			margin-top: var(--mg);
			margin-left: var(--mg);
			width: var(--circle-size);
			height: var(--circle-size);
			border: 2px solid;
			border-color: var(--border-color) transparent var(--border-color) transparent;
			border-radius: 50%;
			transition: border-color 0.5s;
			animation: rotate 1.2s linear infinite;
		}
	}

	&__img-box {
		width: calc(var(--size) * 0.8);

		&::before {
			@include before-img-size(140, 110, false);
		}
	}

	&__img {
		animation: shake-body 1s infinite alternate linear;
		transform-origin: bottom center;
	}

	&__text {
		font-size: rem-calc(12);
	}

	&.is-loaded {
		color: $primary-color;
		opacity: 0;
		transition-delay: 1.5s;

		#{$root} {
			&__cont {
				transition-delay: 0.5s;
				transform: scale(1.5);
				opacity: 0;
			}

			&__img-box {
				animation: bounce 0.5s;
			}
		}
	}

	@include breakpoint(medium) {
		&__cont {
			--size: #{rem-calc(150)};
		}
	}
}

@keyframes shake-body {
	0% {
		transform: rotate(10deg);
	}

	100% {
		transform: rotate(-10deg);
	}
}
