@import './layout/common';

// 諮詢樣式
.consul-open{
	display: none;
	position: fixed;
	bottom: 0;
	width: calc(100% - 30px);
	left: 0;
	margin: rem-calc(0 15);
	cursor: pointer;
	z-index: 1000;
	padding: #{rem-calc(8 20)};
	background-color: #fff2b1;
	border-top-left-radius: #{rem-calc(15)};
	border-top-right-radius: #{rem-calc(15)};
	text-align: center;
	font-size: rem-calc(16);
	p {
		font-weight: 500;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
	}

	i {
		font-size: rem-calc(30);
	}
	@include breakpoint(medium down) {
		display: block;
	}
}

@mixin consultStyle(){
    width: 100%;
	padding-left: #{rem-calc(12)};
	&-form {
		border-radius: #{rem-calc(15)};
		box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.08);
		background-color: $white;
		margin-left: rem-calc(20);
		overflow: hidden;
	}
	&-box{
		width: 100%;
	}
	&-form__title {
		padding: #{rem-calc(15)};
		background-color: #fff2b1;
		border-top-left-radius: #{rem-calc(15)};
		border-top-right-radius: #{rem-calc(15)};
		text-align: center;
		font-size: rem-calc(16);
		position: relative;
		z-index: 30;
		p {
			font-weight: 500;
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: center;
		}

		i {
			font-size: rem-calc(30);
			line-height: 1;
		}
		.black_link{
			i{
				font-size: rem-calc(20);
			}
		}
	}
	&-in{
		background: $white;
		border-radius: rem-calc(0 0 15 15);
		padding: rem-calc(30 10);
	}

	&__bottom {
		padding: 0 #{rem-calc(15)} #{rem-calc(0)} #{rem-calc(15)};
		border-bottom-left-radius: #{rem-calc(15)};
		border-bottom-right-radius: #{rem-calc(15)};
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
	}

	&__text {
		width: 100%;
		font-size: #{rem-calc(14)};
	}

	.close-button {
		position: relative;
		top: auto;
		right: auto;
	}
	@include breakpoint(medium down) {
		&-form {
			display: none;
			background: rgba(0, 0, 0, .45);
			border-radius: rem-calc(0);
			box-shadow: none;
			width: 100vw !important;
			height: 100vh !important;
			max-width: 100vw !important;
			top: 0 !important;
			left: 0 !important;
			right: 0 !important;
			position: fixed !important;
			margin-left: 0;
			&.is-open{
				visibility: visible;
				opacity: 1;
				transition: all .3s;
				z-index: 1000;
				display: block !important;
			}
		}
		&-box{
			width: 90%;
			margin: 5% auto;
			height: 85%;
		}
		&-form__title {
			display: flex;
			justify-content: space-between;
			padding: rem-calc(7.5 15);
			height: rem-calc(45);
			align-items: center;
			width: 100%;
		}
		&-in{
			overflow-y: auto;
			@include tiny-scrollbar($gray-350, $white);
			padding: rem-calc(20 10);
			height: 85%;
		}
	}
	@include breakpoint(xlarge only) {
		&-in{
			padding: rem-calc(15 0);
		}
		&-form__title {
			padding: #{rem-calc(8 15)};
		}
	}
}
