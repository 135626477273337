// Sass Mixins
// ---------------------------------------- [START] Flex
@mixin fc($justify, $align) {
	display: flex;

	@if $justify {
		justify-content: $justify;
	}

	@if $align {
		align-items: $align;
	}
}

// ---------------------------------------- [END] Flex

// ---------------------------------------- [START] BEM Mixin
// 遵循BEM的規則，於class增加tag（僅限於單層方式，多層不適用）
// 可使用（單層）： .component__link => a.component__link
// 不適用（多層）： .component .component__link => .component a.component__link
@mixin bem-tag($tag: 'a') {
	$current: &;

	@at-root #{$tag}#{$current} {
		@content;
	}
}

// ---------------------------------------- [END] BEM Mixin

// ---------------------------------------- [START] before-img-size
// 使用 ::before 的 padding-top 撐高度
@mixin before-img-size($width: 1, $height: 1, $build-other: true) {
	@if $build-other {
		content: '';
		display: block;
	}

	padding-top: percentage($height / $width);
}

// ---------------------------------------- [END] before-img-size

// ---------------------------------------- [START] Media Query 相關
// Hover Media Query (使用 Foundation Mixin)
$project-has-ie: false; // 專案是否有含 IE => 有 IE 就不能使用 @media hover

@mixin breakpoint-hover($hover-enable: true) {

	// 有 IE 專案
	@if $project-has-ie {
		@if $hover-enable {
			@include breakpoint(xlarge) {
				@content;
			}
		}

		@else {
			@include breakpoint(xlarge down) {
				@content;
			}
		}
	}

	// 無 IE 專案
	@else {
		@if $hover-enable {
			@media (hover: hover) {
				@content;
			}
		}

		@else {
			@media (hover: none) {
				@content;
			}
		}
	}
}

// Breakpoint Between (使用 Foundation Mixin)
@mixin breakpoint-between($start, $end) {
	@media screen and #{breakpoint($start)} and #{breakpoint($end down)} {
		@content;
	}
}

// ---------------------------------------- [END] Media Query 相關

// ---------------------------------------- [START] Custom Scroll Bar
$scrollbar-thumb-color: $primary-color; // 操作顏色
$scrollbar-track-color: #eee; // 滾軸背景
$scrollbar-width: 6px; // 寬度(瀏覽器預設為15px)
$scrollbar-border-radius: 6px; // 圓角，Firefox不支援

@mixin custom-scroll-bar($thumb-color: $scrollbar-thumb-color, $track-color: $scrollbar-track-color) {

	// For Chrome, Opera, Safari, Android, iOS
	&::-webkit-scrollbar {
		width: $scrollbar-width;
		height: $scrollbar-width;
	}

	&::-webkit-scrollbar-track {
		background: $track-color;
	}

	&::-webkit-scrollbar-thumb {
		background: $thumb-color;
		border-radius: $scrollbar-border-radius;

		&:hover {
			background: darken($thumb-color, 10%);
		}
	}

	@if & {
		// For Firefox (版本必須64+)
		// 如果是整頁<body>要使用，必須將Class增加於<html>標籤上
		// Ref: https://stackoverflow.com/a/6165489/11240898
		// 顏色：操作顏色(Thumb) 滾軸背景(Track)
		scrollbar-color: $thumb-color $track-color;
		// 寬度只有 auto | thin | none 選項
		// auto為15px，thin為6px，none為不顯示
		scrollbar-width: thin;
	}
}

// ---------------------------------------- [END] Custom Scroll Bar

// ---------------------------------------- [START] tiny Custom Scroll Bar
$scrollbarTiny-thumb-color: #d4d3d1; // 操作顏色
$scrollbarTiny-track-color: $white; // 滾軸背景
$scrollbarTiny-width: 4px; // 寬度(瀏覽器預設為15px)
$scrollbarTiny-border-radius: 4px; // 圓角，Firefox不支援
@mixin tiny-scrollbar ($thumb-color: $scrollbarTiny-thumb-color, $track-color: $scrollbarTiny-track-color) {
	// For Chrome, Opera, Safari, Android, iOS
	&::-webkit-scrollbar {
		width: $scrollbarTiny-width;
		height: $scrollbarTiny-width;
	}

	&::-webkit-scrollbar-track {
		background: $track-color;
	}

	&::-webkit-scrollbar-thumb {
		background: $thumb-color;
		border-radius: $scrollbar-border-radius;

		&:hover {
			background: darken($thumb-color, 10%);
		}
	}

	@if & {
		// For Firefox (版本必須64+)
		// 如果是整頁<body>要使用，必須將Class增加於<html>標籤上
		// Ref: https://stackoverflow.com/a/6165489/11240898
		// 顏色：操作顏色(Thumb) 滾軸背景(Track)
		scrollbar-color: $thumb-color $track-color;
		// 寬度只有 auto | thin | none 選項
		// auto為15px，thin為6px，none為不顯示
		scrollbar-width: thin;
	}
}
// ---------------------------------------- [START] 滾軸遮罩
// 
%scrollbarMask{
	&::before{
		position: absolute;
		display: block;
		content: '';
		width: rem-calc(30);
		height: 100%;
		background: linear-gradient(270deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.00) 100%);
		filter: blur(2px);
		z-index: 0;
		right: 0;
	}
}
	
// ---------------------------------------- [START] Placeholder
@mixin optional-at-root($sel) {
	@at-root #{if(not &, $sel, selector-append(&, $sel))} {
		@content;
	}
}

// Ref: https://stackoverflow.com/a/17181946
@mixin placeholder($color: #ccc) {
	@include optional-at-root('::-webkit-input-placeholder') {
		color: $color;
	}

	@include optional-at-root(':-moz-placeholder') {
		color: $color;
	}

	@include optional-at-root('::-moz-placeholder') {
		color: $color;
	}

	@include optional-at-root(':-ms-input-placeholder') {
		color: $color;
	}
}

// ---------------------------------------- [END] Placeholder

// ---------------------------------------- [START] <select>
// Select Arrow
// 轉換SVG To Base64(data image): https://www.zhangxinxu.com/sp/svgo/
@function select-arrow-builder($color: $body-font-color) {
	$color-normal: str-replace('' + $color, '#', '');
	$build-svg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='%23#{$color-normal}' d='M25.1 29.65a6.91 6.91 0 0 1-1.7 0 7.06 7.06 0 0 1-3.47-1.75c-1.4-1.13-3-2.78-5.33-5.09l-5.68-5.73a1.3 1.3 0 0 0-1.84 1.84l5.73 5.78a74 74 0 0 0 5.49 5.23 9.4 9.4 0 0 0 4.77 2.29 8.48 8.48 0 0 0 2.36 0 9.4 9.4 0 0 0 4.77-2.29 74 74 0 0 0 5.49-5.23l5.73-5.78a1.3 1.3 0 0 0-1.84-1.84l-5.68 5.73c-2.29 2.31-3.93 4-5.33 5.09a7.06 7.06 0 0 1-3.47 1.75z' fill-rule='evenodd'/%3E%3C/svg%3E";
	@return $build-svg;
}

@function select-arrow-reverse-builder($color: $body-font-color) {
	$color-normal: str-replace('' + $color, '#', '');
	$build-svg: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48'%3E%3Cpath fill='%23#{$color-normal}' d='M23.4 19.35a6.91 6.91 0 0 1 1.7 0 7.06 7.06 0 0 1 3.47 1.75c1.4 1.13 3 2.78 5.33 5.09l5.68 5.73a1.3 1.3 0 0 0 1.84-1.84l-5.73-5.78a74 74 0 0 0-5.49-5.23 9.4 9.4 0 0 0-4.77-2.29 8.48 8.48 0 0 0-2.36 0 9.4 9.4 0 0 0-4.77 2.29 74 74 0 0 0-5.49 5.23l-5.73 5.78a1.3 1.3 0 0 0 1.84 1.84l5.68-5.73c2.29-2.31 3.93-4 5.33-5.09a7.06 7.06 0 0 1 3.47-1.75z' fill-rule='evenodd'/%3E%3C/svg%3E";
	@return $build-svg;
}

$select-arrow: select-arrow-builder();
$select-arrow-white: select-arrow-builder($white);
$select-arrow-reverse: select-arrow-reverse-builder();
$select-arrow-reverse-white: select-arrow-reverse-builder($white);

// Select Style
@mixin select-style($use-white: false) {
	padding-right: rem-calc(40);
	text-overflow: ellipsis;
	cursor: pointer;

	// Arrow
	background: no-repeat right 12px center / 15px;

	@if $use-white {
		background-image: url('#{$select-arrow-white}');
	}

	@else {
		background-image: url('#{$select-arrow}');
	}

	@include breakpoint(medium) {
		padding-right: rem-calc(45);
		background-position: right 19px center;
		background-size: 18px;
	}
}

// ---------------------------------------- [END] <select>

// ---------------------------------------- [START] 文字溢行
@mixin clamp($line-limit: 1, $line-height: 1.6) {
	position: relative;
	display: block;
	display: -webkit-box;
	overflow: hidden;
	max-width: 100%;
	line-height: 1.6;
	line-height: var(--line-height, $line-height);
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: var(--line-limit, $line-limit);
}

// ---------------------------------------- [END] 文字溢行

// ---------------------------------------- [START] Toggle Show Style
// Toggle Show Style
// https://stackoverflow.com/a/30531678
$d-opacity: 0.25s;
$d-disappear: 0.5s;

/// @param {string} $switch - 開關class
/// @param {string} $targetEl - 要縮起展開的物件（通常為<a> ）
// 放在要加的 "is-open"(開關Class) class 的物件上
// @include toggle-menu-item('開關Class', '內層展開收闔物件，通常為<a>')
@mixin toggle-menu-item--disable {
	margin: 0;
	padding-top: 0;
	padding-bottom: 0;
	font-size: 0;
	line-height: 0;
	color: transparent;
	border-width: 0;
	-webkit-speak: none;
	opacity: 0;
	transition: opacity $d-opacity,
		font-size $d-disappear $d-opacity,
		line-height $d-disappear $d-opacity,
		margin $d-disappear $d-opacity,
		padding $d-disappear $d-opacity,
		color $d-opacity;
}

@mixin toggle-menu-item--enabled {
	transition: font-size $d-opacity,
		margin $d-opacity,
		padding $d-opacity,
		line-height $d-opacity,
		color $d-opacity,
		opacity $d-disappear $d-opacity;
}

@mixin toggle-menu-item($switch, $targetEl, $extendPlaceholderSelector) {

	// 使用 @extend 統整樣式
	// 因為要用此方法，必須先在同一個 @media 底下建立替代 Selector，範例：
	// ※命名結束一定要為「--disable」、「--enabled」，前面文字要相同
	// @at-root %toggle-menu-common--disable {
	// 	@include toggle-menu-item--disable;
	// }
	// @at-root %toggle-menu-common--enabled {
	// 	@include toggle-menu-item--enabled;
	// }
	@if $extendPlaceholderSelector {

		// Hide
		&:not(#{$switch}) {
			#{$targetEl} {
				@extend #{$extendPlaceholderSelector}--disable;
			}
		}

		// Show
		&#{$switch} {
			#{$targetEl} {
				@extend #{$extendPlaceholderSelector}--enabled;
			}
		}
	}

	@else {

		// Hide
		&:not(#{$switch}) {
			#{$targetEl} {
				@include toggle-menu-item--disable;
			}
		}

		// Show
		&#{$switch} {
			#{$targetEl} {
				@include toggle-menu-item--enabled;
			}
		}
	}

}

// ---------------------------------------- [END] Toggle Show Style

// ---------------------------------------- [START] Animation Set
@mixin set-animate($animateEl) {
	@each $class in $animateEl {
		$i: index($animateEl, $class);
		$i: $i;

		#{$class} {
			opacity: 0;
			transform: translateY(50px);
			transition-duration: 1s;
			transition-property: opacity, transform;
			transition-delay: #{$i * 0.3s};
		}
	}
}

@mixin set-animate-default($animateEl) {
	@each $class in $animateEl {
		$i: index($animateEl, $class);
		$i: $i;

		#{$class} {
			transition-duration: 1s;
			transition-property: opacity, transform;
			transition-delay: #{$i * 0.3s};
		}
	}
}

@mixin set-animate-from($animateEl) {
	@each $class in $animateEl {
		$i: index($animateEl, $class);
		$i: $i;

		#{$class} {
			opacity: 0;
			transform: translateY(50px);
		}
	}
}

@mixin set-animated($animateEl) {
	@each $class in $animateEl {
		#{$class} {
			opacity: 1;
			transform: none;
		}
	}
}

@mixin set-animate-flow($animateEl) {
	&.js-ani {
		@include set-animate($animateEl);

		&.is-animated {
			@include set-animated($animateEl);
		}
	}
}

@mixin set-animate-flow--not($animateEl) {
	&.js-ani {
		@include set-animate-default($animateEl);

		&:not(.is-animated) {
			@include set-animate-from($animateEl);
		}
	}
}


// ---------------------------------------- [END] Animation Set


// ---------------------------------------- [START] Link Style Set
@mixin set-link-color($color, $hover-color) {
	color: $color;
	transition: background-color .25s ease-out, color .25s ease-out;
	@include breakpoint-hover {
		&:hover,
		&:focus{
			color: $hover-color;
		}
	}
	.be-icon{
		margin-right: rem-calc(5);
		&::before{
			transform: scale(1.5)
		}
	}
}

@mixin a-link_black{
	@include set-link-color($black, $yellow);
}

// ---------------------------------------- [END]  Link Style Style

// ---------------------------------------- [START] 內頁通用設定
@mixin inside_page_margin{
	margin: rem-calc(100) auto rem-calc(60) auto;
	@media screen and (max-width: 42em){
		margin: rem-calc(40) auto rem-calc(20) auto;
	}
}

@mixin inside_boj_margin{
	margin: rem-calc(0) auto rem-calc(60) auto;
	@media screen and (max-width: 42em){
		margin: rem-calc(0) auto rem-calc(20) auto;
	}
}
// set mt 30
@mixin inside_boj_margin_tiny{
	margin-top: rem-calc(30);
	@media screen and (max-width: 42em){
		margin-top: rem-calc(20)
	}
}
// set mt 35
@mixin inside_boj_mt_35{
	margin-top: rem-calc(35);
	@media screen and (max-width: 42em){
		margin-top: rem-calc(20)
	}
}
// set mt 40
@mixin inside_boj_mt_40{
	margin-top: rem-calc(40);
	@media screen and (max-width: 42em){
		margin-top: rem-calc(20)
	}
}
// set mt 45
@mixin inside_boj_margin_s{
	margin-top: rem-calc(45);
	@media screen and (max-width: 42em){
		margin-top: rem-calc(20)
	}
}
// set mt 60
@mixin inside_boj_margin_s_60{
	margin-top: rem-calc(60);
	@media screen and (max-width: 42em){
		margin-top: rem-calc(30)
	}
}

// set mt 100
@mixin inside_boj_margin_s_100{
	margin-top: rem-calc(100);
	@media screen and (min-width: 1023px) and  (max-width: 1560px){
		margin-top: rem-calc(60);
	}
	@include breakpoint(large only) {
		margin-top: rem-calc(40);
	}
	@include breakpoint(medium only) {
		margin-top: rem-calc(40);
	}
	@include breakpoint(small only) {
		margin-top: rem-calc(30);
	}
}
// set mt 160
@mixin inside_boj_margin_s_160{
	margin-top: rem-calc(160);
	@media screen and (min-width: 1023px) and  (max-width: 1560px){
		margin-top: rem-calc(100);
	}
	@include breakpoint(large only) {
		margin-top: rem-calc(80);
	}
	@include breakpoint(medium only) {
		margin-top: rem-calc(60);
	}
	@include breakpoint(small only) {
		margin-top: rem-calc(50);
	}
}
// set mb 35
@mixin inside_boj_mb__35{
	margin-bottom: rem-calc(20);
	@include breakpoint(xxlarge up) {
		margin-bottom: rem-calc(35);
	}
}
// set mb 45
@mixin inside_boj_mb__45{
	margin-bottom: rem-calc(20);
	@include breakpoint(xxlarge up) {
		margin-bottom: rem-calc(45);
	}
	@include breakpoint(medium only) {
		margin-bottom: rem-calc(30);
	}
}
// set mb 60
@mixin inside_boj_mb__60{
	margin-bottom: rem-calc(60);
	@media screen and (max-width: 42em){
		margin-bottom: rem-calc(30)
	}
}
// set mb 100
@mixin inside_boj_mb__100{
	margin-bottom: rem-calc(20);
	@include breakpoint(xxlarge up) {
		margin-bottom: rem-calc(100);
	}
	@include breakpoint(xlarge only) {
		margin-bottom: rem-calc(80);
	}
	@include breakpoint(large only) {
		margin-bottom: rem-calc(70);
	}
	@include breakpoint(medium only) {
		margin-bottom: rem-calc(30);
	}
}
// set mb 190
@mixin inside_boj_mb__190{
	margin-bottom: rem-calc(30);
	@include breakpoint(xxlarge up) {
		margin-bottom: rem-calc(190);
	}
	@include breakpoint(xlarge only) {
		margin-bottom: rem-calc(100);
	}
	@include breakpoint(large only) {
		margin-bottom: rem-calc(70);
	}
	@include breakpoint(medium only) {
		margin-bottom: rem-calc(50);
	}
}
// 用於訊息有新的顯示時
@mixin hasNew($top, $right){
	position: relative;
	&:after{
		content: '';
		position: absolute;
		display: block;
		top: rem-calc($top);
		right: rem-calc($right);
		background: $red;
		width: rem-calc(10);
		height: rem-calc(10);
		border-radius: 100%;
	}
}
// ---------------------------------------- [END]  內頁通用設定 Style

// ---------------------------------------- [START] object-fit圖片計算
// object-fit圖片計算
// 僅提供Before內部的計算，只能在使用object-fit物件使用
@mixin imgSize($width, $height) {
	&:before{
		padding-top: round($height / $width * 100% * 100) / 100
	}
}
// ---------------------------------------- [END]  object-fit圖片計算

// ---------------------------------------- [START] 列表樣式
/* 卡片備註樣式 */
@mixin noteList($note-color, $border-color) {
	color: $note-color;
	font-size: rem-calc(12);
	display: flex;
    flex-direction: row;
    margin: rem-calc(10 0 0 0);
	list-style: none;
	li{
		border-right: 1px solid $border-color;
		padding: rem-calc(0 10);
		line-height: 1;
		&:first-child{
			padding-left: 0;
		}
		&:last-child{
			padding-right: 0;
			border: none;
		}
	}
	
	@media screen and (min-width: 1180px) and  (max-width: 1440px){
		li{
			padding: rem-calc(0 10);
		}
	}
}
// ---------------------------------------- [END]  列表樣式

// ---------------------------------------- [START] 內頁子選單
// $bg_color背景顏色
// $a_color連結顏色
// $active_color加入is-active的顏色
@mixin inside_sub_menu($bg_color, $a_color, $active_color){
	border-radius: rem-calc(50);
	background: $bg_color;
	box-shadow: 2px 3px 8px 0px rgba(0, 0, 0, 0.25) inset;
	padding: rem-calc(0 20);
	margin-bottom: 2px;
	overflow-y: hidden;
	overflow-x: hidden;
	ul{
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		align-content: center;
		padding: rem-calc(0 30px);
		width: 100%;
		overflow-y: hidden;
		margin-left: 0;
		margin-bottom: -1px;
		@include tiny-scrollbar;
	}
	a{
		color: $a_color;
		span{
			color: $active_color;
			margin-left: rem-calc(5);
		}
	}
	li{
		list-style: none;
		padding: rem-calc(14 20);
		border-bottom: 5px solid transparent;
		flex: 0 0 auto;
		@include breakpoint-hover{
			&:hover,
			&:focus {
				color: $active_color;
				font-weight: bold;
				transition: all .3s;
				border-bottom: 5px solid $active_color;
				a{color: $active_color;font-weight: bold;}
			}
		}

		&.is-active{
			border-bottom: 5px solid $active_color;
			a{
				color: $active_color;
				font-weight: bold;
			}
		}
	}
	@include breakpoint(medium only){
		padding: rem-calc(0 10);
		overflow-x: auto;
		li{
			padding: rem-calc(10 12);
		}
	}
	@include breakpoint(small only){
		ul{
			padding: rem-calc(0);
		}
		li{
			padding: rem-calc(10);
		}
	}
}

// ---------------------------------------- [START] 流體排版
// Fluid Typography | 流體排版
// Ref: https://css-tricks.com/snippets/css/fluid-typography/

$min-width: 320px;
$max-width: 1200px;
$min-font: 16px;
$max-font: 24px;

@mixin fluid-type($min-vw: $min-width, $max-vw: $max-width, $min-font-size: $min-font, $max-font-size: $max-font) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			font-size: $min-font-size;
			@media screen and (min-width: $min-vw) {
				font-size: calc(
					#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
						((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
				);
			}
			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;
			}
		}
	}
}
// ---------------------------------------- [START] 麵包屑 白色字體
@mixin breadcrumbsWhite{
	.breadcrumbs {
		li{
			color: $white;
		}
		a{
			color: $white;
			@include breakpoint-hover {
				&:hover,
				&:focus {
					color: $primary;
				}
			}
		}
		li:not(:last-child)::after{
			color: $white;
		}
	}
}


// ---------------------------------------- [START] 大型滿版視窗
@mixin revealFullScreen{
	width: 100%;
    height: 100%;
    top: 0 !important;
    right: 0 !important;
    max-width: 100%;
    border-radius: 0;
	padding: 5%;
	overflow: hidden;
	.grid-x{
		@include tiny-scrollbar($gray-350, $white);
		width: 100%;
	}
	.btn-box{
		width: 100%;
		margin-bottom: rem-calc(20);
	}
	&.has-bg::after{
		display: none;
	}
    .float-circle {
		z-index: -1;
        &:nth-child(1){
			bottom: 9%;
			right: -2%;
            --size: 80px;
			--ani-to-y: 15px;
            @include breakpoint(medium) {
                --size: 150px;
            }
        
            @include breakpoint(large) {
                --size: 250px;
            }
        }
        &:nth-child(2){
            --size: 30px;
			top: -20px;
			right: 10%;
            @include breakpoint(medium) {
                --size: 40px;
            }
        
            @include breakpoint(large) {
                --size: 50px;
            }
        }
		&:nth-child(3){
            --size: 40px;
			bottom: 35%;
			right: 10%;
			--ani-to-y: 10px;
            @include breakpoint(medium) {
                --size: 60px;
            }
        
            @include breakpoint(large) {
                --size: 80px;
            }
        }
    }
}


// ---------------------------------------- [START] 小型tag 按鈕
@mixin small_tag_btn{
	font-size: rem-calc(14);
	padding: rem-calc(8 15);
	margin-bottom: rem-calc(15);
	background: $white;
	&:not(:last-child){
		margin-right: rem-calc(10);
	}
}


// ---------------------------------------- [START] 滾動背景圖片
@mixin img_fixed{
	background-attachment: fixed;
	background-repeat: no-repeat;
	background-size: cover;
}

// ---------------------------------------- [START] 大型箭頭樣式30*30
@mixin arrow-style($bg-color){
	background: $bg-color;
	padding-left: var(--gap-title);
	z-index: 1;
	.icon-box{
		--size: #{rem-calc(35)};
		background: $white;
		border: 2px solid $primary;
		i{
			color: $primary;
		}
	}
}
