%before-style{
	--size: #{rem-calc(10)};

	content: "";
	position: absolute;
	z-index: -1;
	bottom: calc(var(--size) / -2);
	left: calc(50% - var(--size) / 2);
	display: block;
	width: var(--size);
	height: var(--size);
	background: $white;
	// box-shadow: 0 0 10px rgba($black, .08);
	transform: rotate(45deg);
}
%tooltip {
	padding: rem-calc(15);
	border: 0 none;
	box-shadow: $primary-shadow;
	width: auto;
	.inside {
		position: relative;
		display: block;
		// padding: rem-calc(15);
		background: $white;
		border-radius: rem-calc(15);
		ul{
			margin-bottom: 0;
			list-style: none;
			line-height: 1.6;
			li {
				position: relative;
				line-height: 1.6;
	
				&::before {
					content: '';
					position: absolute;
					top: calc(0.8em - 1px);
					left: -14px;
					width: 2px;
					height: 2px;
					background: currentColor;
				}
			}
		}
	}

	&::before {
		@extend %before-style;
	}
	&.top{
		&::before{
			display: none;
		}
		&::after{
			@extend %before-style;
		}
	}
	&.bottom {
		&::before{
			transform: rotate(45deg) !important;
			top: -1px;
		}
	}
	&.left {
		&::before{
			transform: rotate(45deg) !important;
			top: -1px;
		}
	}
}

.tooltip {
	box-shadow: 0 0 10px rgba($black, .08);
	ul{
		margin-bottom: 0;
		list-style: none;
		li {
			position: relative;

			&::before {
				content: '';
				position: absolute;
				top: 13px;
				left: -14px;
				width: 2px;
				height: 2px;
				background: currentColor;
			}
		}
	}
}
.has-tip{
	border: none;
}
.dropdown-pane {
	@extend %tooltip;
}
