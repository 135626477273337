// Popup / Modal / 彈跳視窗
.reveal{
	$root: &;

	border-radius: rem-calc(15);
	border: transparent;

	// set input
	
	&.revel_center{
		padding-bottom: rem-calc(60);
		top: 50% !important;
		transform: translateY(-50%);
        @include breakpoint(medium down) {
            width: calc(100% - 30px);
			margin: rem-calc(15);
			height: auto;
			min-height: auto;
        }
	}
	&.large{
		padding: rem-calc(40)
	}
    .close-button{
        .be-icon:before{
            margin: 0;
        }
    }

	h5{
		margin-top: rem-calc(50);
		margin-bottom: rem-calc(30);
	}

	p{
		color: $gray-400;
	}

	.button{
		margin-bottom: 0;
	}

	.no-pd-btn{
		padding: rem-calc(8 0);
	}
	//裝飾球
	&__float-circle{
		--size: 45px !important;
		right: 15%;
    	top: -12%;
		width: var(--size);
		height: var(--size);

		@include breakpoint(medium) {
			--size: 65px !important;
		}

		@include breakpoint(large) {
			--size: 80px !important;
		}
	}
    &.has-bg{
		position: relative;
		&::before, &::after{
			position: absolute;
			content: '';
			display: block;
			background-repeat: no-repeat;
			background-size: cover;
			z-index: -1;
		}
		&::before{
			background-image: url("../images/popup/bg_l.png");
			top: 0;
			left: 0;
			width: rem-calc(113);
			height: rem-calc(129);
		}
		&::after{
			background-image: url("../images/popup/bg_r.png");
			bottom: 0;
			right: 0;
			width: rem-calc(192);
			height: rem-calc(240);
		}
    }

	// login 
	&_login{
		border: transparent;
		hr{
			background: #FAD12F;
			background: linear-gradient(135deg, #FAD12F 0%, #FF8D45 100%);
			margin: rem-calc(30) 0;
			border: none;
			height: 1px;
		}
	}

	&_btn_group{
		margin-top: rem-calc(10);
	}
}
