// 水平 Padding (Medium+ 避免左右寬度過窄)
.grid-container {
	// --pd-x: #{rem-calc(16)}; // 設定於:root
	padding-right: var(--pd-x);
	padding-left: var(--pd-x);

	// 比較寬的內容(Header, Index Banner)
	&.large-cont {
		// 螢幕寬 - 左右距離 + large padding-x (有改過預設值)
		max-width: rem-calc(1920 - 125 * 2 + 100 * 2);
	}
}

// 區塊內容
.sec {
	--sec-pd-y: #{rem-calc(50)};

	position: relative;
	padding-top: var(--sec-pd-y);
	padding-bottom: var(--sec-pd-y);

	@include breakpoint(medium) {
		--sec-pd-y: #{rem-calc(100)};
	}

	&.inside_sec{
		--sec-pd-y: #{rem-calc(0)};

		position: relative;
		padding-top: var(--sec-pd-y);
		padding-bottom: var(--sec-pd-y);

		@include breakpoint(medium) {
			--sec-pd-y: #{rem-calc(0)};
		}
	}
}

// 區塊標題
.sec-title {
	$root: &;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: rem-calc(30);
	text-align: center;

	&__title-box {
		position: relative;
		display: inline-block;
	}

	&__title {
		color: $black;
		margin-bottom: em-calc(18 / 48);
	}

	&__deco {
		position: absolute;
		left: 100%;
		bottom: 65%;
		font-size: rem-calc(24);
		color: $primary-color;
		transform: rotate(-15deg) !important;
		transform-origin: left bottom;
	}

	&__desc {
		font-weight: 300;
		color: $gray-300;
	}

	&.text-left {
		text-align: left;
	}

	&.js-ani {
		--delay: 0s;

		#{$root} {
			&__title {
				span {
					display: inline-block;
					transform: rotateY(90deg);
					transition: transform 0.5s;

					@for $i from 1 through 20 {
						&:nth-child(#{$i}) {
							transition-delay: calc(var(--delay) + 0.05s * #{$i});
						}
					}
				}
			}

			&__deco {
				clip-path: polygon(0 0, 0% 0%, 0% 100%, 0% 100%);
				transition: clip-path 0.5s calc(var(--delay) + 0.25s);
			}

			&__desc {
				opacity: 0;
				transition: opacity 0.5s calc(var(--delay) + 0.5s);
			}
		}
	}

	&.is-animated {
		#{$root} {
			&__title {
				span {
					transform: rotateY(0);
				}
			}

			&__deco {
				clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
			}

			&__desc {
				opacity: 1;
			}
		}
	}

	@include breakpoint(medium) {
		margin-bottom: rem-calc(40);

		&__deco {
			font-size: rem-calc(32);
		}
	}
}

// 區塊標題＋欄位樣式
.sec-bn-input{
	$root: &;
	$size: #{rem-calc(5)};
	box-shadow: $sec-bn-shadow;
	padding: calc(#{$size} * 8) 0;
	background: $white;
	z-index: 10 !important;
	&__title-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: rem-calc(0 0 36 0);
	}
	// 區塊標題＋欄位樣式＋文案
	&__text{
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		@include inside_boj_mb__35;
		.h2{
			margin-bottom: rem-calc(10);
		}
		#search-box{
			width: rem-calc(236);
			margin-top: rem-calc(10);
		}
	}
	&__text-box{
		width: 66%;
	}
	&__text-block{
		@include inside_boj_mb__35;
		h3{
			font-weight: 300;
		}
	}
	&.has-select{
		.input-normal{
			width: 50%;
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}
		#search-box{
			width: rem-calc(236);
			margin-left: rem-calc(20);
		}
		select{
			width: rem-calc(180);
			margin: 0;
		}
	}
	.icon-wrapper{
		margin: 0;
	}
	@include breakpoint(medium down) {
		&__title-box{
			flex-direction: column;
			align-items: flex-start;
			margin: rem-calc(30 0);
		}
		&__text{
			flex-direction: column;
			align-items: flex-start;
			#search-box{
				width: 100%;
			}
		}
		&__text-box{
			width: 100%;
		}
		#search-box, .icon-wrapper {
			width: 100%;
		}
		&.has-select{
			.input-normal{
				width: 100%;
			}
			#search-box, select{
				width: 50%;
			}
		}
	}

	// 移除廣告後樣式
	&.re-ad{
		border-top: 1px solid #d9dce3;
	}
}
// 區塊ＢＮ圖＋標題樣式
.sec-bn-title{
	$root: &;
	background-position: top;
	@include img_fixed;
	// 白色文字顯示(麵包屑、文字白色)
	&.bn_white, &.bn_white_300 , &.bn_white_img{
		@include breadcrumbsWhite;
		.h2{
			color: $white;
		}
	}
	&.bn_white_300{
		height: rem-calc(300);
		background-size: 100% auto;
		background-position: center;
	}
	// 設定圖片致中，無設定高度
	&.bn_white_img{
		background-size: 100% auto;
		background-position: center;
	}
	// 字體致中：news
	&__word-box{
		display: flex;
		justify-content: center;
	}
	&__word{
		@include inside_boj_mb__60;
		@include inside_boj_margin_tiny;
		color: $white;
		p{
			margin-bottom: 0;
		}
	}
	// 字體至左，無欄位
	&__text{
		margin-bottom: rem-calc(25);
	}
	@include breakpoint(medium down) {
		&.bn_white_300, &.bn_white_img{
			background-position: top;
			background-attachment: initial;
			background-size: 100% 100%;
		}
	}
	@include breakpoint(large only){
		&.bn_white_300, &.bn_white_img{
			background-position: top;
			background-attachment: initial;
			background-size: 100% 100%;
		}
	}
}
// 區塊廣告
.sec-ad{
	$root: &;
	$size: #{rem-calc(5)};

	background-color: $orange-light-50;
	width: 100%;
	display: flex;
	text-align: center;
	justify-content: center;
	i{
		font-size: x-large;
	}

	&__text-box {
		padding: calc(#{$size} * 3);
		color: $white;
		display: flex;
		align-items: center;
		span{
			margin-right: rem-calc(15);
			display: flex;
			&:last-child{
				margin-right: 0;
			}
		}
		a {
			color: $white;
			font-size: rem-calc(14);
		}
	}
	@include breakpoint(medium down) {
		font-size: rem-calc(12);
		i{
			font-size: rem-calc(18);
		}
		span{
			margin-right: rem-calc(5);
		}
		&__text-box {
			a {
				font-size: rem-calc(12);
			}
		}
	}
	@include breakpoint(340 down) {
		&__text-box {
			display: block;
		}
		span{
			margin-right: rem-calc(0);
		}
	}
}


