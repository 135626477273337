// Swiper 10.0.3
// Import node_module 檔案資料夾不能相同，即不能取「swiper」改取「plugin-swiper」

// Core
@import 'swiper/swiper.scss';

// Modules
// @import 'swiper/modules/virtual.scss';
@import 'swiper/modules/navigation.scss';
@import 'swiper/modules/pagination.scss';
// @import 'swiper/modules/scrollbar.scss';
// @import 'swiper/modules/zoom.scss';
// @import 'swiper/modules/lazy.scss';
@import 'swiper/modules/a11y.scss';
// @import 'swiper/modules/thumbs.scss';
// @import 'swiper/modules/free-mode.scss';
// @import 'swiper/modules/grid.scss';
@import 'swiper/modules/effect-fade.scss';
// @import 'swiper/modules/effect-cube.scss';
// @import 'swiper/modules/effect-flip.scss';
// @import 'swiper/modules/effect-coverflow.scss';
@import 'swiper/modules/effect-creative.scss';
// @import 'swiper/modules/effect-cards.scss';


// ===================================

// Customer Style
// 客製化外層，為了讓Nav可以破版，Nav要放在此層下方，非.swiper底下
.swiper-box {
	--swiper-nav-size: #{rem-calc(36)};
	--pd-x: var(--pd-x-global);
	--mg-x: calc(var(--pd-x) * -1);
	--swiper-nav-pos: calc(var(--pd-x) / 2);

	position: relative;
	margin-left: var(--mg-x); // 恢復大小
	margin-right: var(--mg-x); // 恢復大小
	padding-left: var(--pd-x);
	padding-right: var(--pd-x);

	@include breakpoint(medium) {
		--swiper-nav-pos: 0;
		--swiper-nav-size: #{rem-calc(45)};
		--pd-x: calc(var(--swiper-nav-size) / 2);
	}

	// 加在 .swiper 上
	@at-root %swiper-cont-expand {
		--pd-x: var(--pd-x-global);
		--mg-x: calc(var(--pd-x) * -1);

		margin-left: var(--mg-x);
		margin-right: var(--mg-x);
		padding-left: var(--pd-x);
		padding-right: var(--pd-x);
	}

	.swiper-cont-expand {
		@extend %swiper-cont-expand;

		@include breakpoint($global-width) {
			--pd-x: calc((100vw - #{$global-width}) / 2 + var(--pd-x-global));
		}

		&--large-down {
			@extend %swiper-cont-expand;

			@include breakpoint(xlarge) {
				margin-left: 0;
				margin-right: 0;
				padding-right: 0;
				padding-left: 0;
			}
		}
	}
}

// Swiper Item
.swiper-wrapper {
	@include bem-tag(ul) {
		margin-bottom: 0;
		margin-left: 0;
		list-style: none;
	}
}

.swiper-slide {
	box-sizing: border-box;
}

// Navigation
%swiper-nav {
	--size: var(--swiper-nav-size, #{rem-calc(45)});
	--swiper-navigation-size: calc(var(--size) * #{(25 / 45)}); // font-size

	margin-top: calc(var(--size) / -2);
	width: var(--size);
	height: var(--size);
	background: $white;
	color: $black;
	border-radius: 99px;
	box-shadow: 0px 1px 14px 2px rgba($black, 0.15);

	&::after {
		font-family: 'icon';
	}

	&:hover,
	&:focus {
		background: $yellow;
		color: $white;
	}
}

.swiper-button-prev {
	@extend %swiper-nav;

	left: var(--swiper-nav-pos);

	&::after {
		@include font-icon-add(arrow-left, false);
	}
}

.swiper-button-next {
	@extend %swiper-nav;

	right: var(--swiper-nav-pos);

	&::after {
		@include font-icon-add(arrow-right, false);
	}
}

// Pagination
:root {
	--swiper-pagination-color: #{$primary};
	--swiper-pagination-bullet-size: 10px;
	--swiper-pagination-bullet-inactive-color: #{$yellow};
	--swiper-pagination-bullet-inactive-opacity: 1;
	--swiper-pagination-bullet-border-radius: 9px;
	--swiper-pagination-bottom: 25px;
}

.swiper-pagination-bullet {
	transition: width 0.2s;

	&:hover,
	&:focus {
		background: $primary-color;
	}
}

.swiper-pagination-bullet-active {
	width: calc(var(--swiper-pagination-bullet-size) * 3.5);
}

.swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
	transition: .2s transform, .2s left, width 0.2s;
}
