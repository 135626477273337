@mixin font-face($font-family, $font-weight, $font-style, $file-path) {
	@font-face {
		font-family: #{$font-family};
		src: url('#{$file-path}.woff') format('woff');
		font-weight: $font-weight;
		font-style: $font-style;
	}
}

@include font-face('Noto Sans TC', bold, normal, '/assets/fonts/NotoSansTC-Bold');
@include font-face('Noto Sans TC', 300, normal, '/assets/fonts/NotoSansTC-Light');
@include font-face('Noto Sans TC', 500, normal, '/assets/fonts/NotoSansTC-Medium');
@include font-face('Dancing Script', bold, normal, '/assets/fonts/DancingScript-Bold');
// /* 中文襯線字 */
// .font-serif {
// 	font-family: 'Noto Serif TC', serif;
// }

// 草寫英文
.font-script {
	font-family: 'Dancing Script', cursive;
	font-weight: bold;
}
